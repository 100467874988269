<template>
  <v-container fluid class="content-wrapper mb-4">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">{{ $t("SigninHealthandSafety.title") }}</h4>
      <v-btn icon class="ml-3" @click="initAll()" :class="loadingData && 'animate__spining'" :disabled="loadingData">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>
    <!-- loading data -->
    <v-row v-if="loadingData" row wrap>
      <v-col v-for="n in 3" :key="n" cols="12">
        <div class="card card-shadow mb-2">
          <v-skeleton-loader type="article"></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>

    <!-- show data -->
    <v-row
      v-else-if="!loadingData && itemsHealthAndSafety.length"
      row
      wrap
      class="mt-0 pt-0"
    >
      <v-col cols="12" class="pt-0">
        <div
          v-for="(item, index) in itemsHealthAndSafety"
          :key="item._id"
          class="card card-shadow pa-5 mb-0"
          :class="index === 0 ? '' : 'mt-5'"
        >
          <h3 class="title-sm mb-2">{{ item.category_name }}</h3>
          <v-card
            outlined
            v-for="(element, i) in item.health_and_safety"
            :key="element._id"
            class="d-flex flex-row align-start justify-space-between mb-2 px-3"
          >
            <div class="d-flex flex-column mt-4">
              <span class="textstyle">{{ element.value }}</span>
            </div>
            <div v-if="element.choice_type == 'select'" class="d-flex flex-column">
              <v-radio-group
                v-model="element.checkrooms"
                row
                color="#1e9ff2"
                @change="checkroomscansaveFunc(element)"
              >
                <v-radio
                  color="#1e9ff2"
                  class="textstyle"
                  :label="$t('SigninHealthandSafety.btn_some_room')"
                  value="some"
                ></v-radio>
                <v-radio
                  color="#1e9ff2"
                  :label="$t('SigninHealthandSafety.btn_all_room')"
                  value="all"
                ></v-radio>
                <v-radio
                  :label="$t('SigninHealthandSafety.btn_no_room')"
                  value="none"
                ></v-radio>
              </v-radio-group>
              <div
                v-if="element.checkrooms == 'some'"
                :class="i + 1 == item.health_and_safety.length && 'mb-5'"
              >
                <template v-for="ele in element.rooms">
                  <v-checkbox
                    class="textstyle"
                    :key="ele._id"
                    v-model="ele.check"
                    :label="`${ele.namevalue}`"
                    style="margin-bottom: 0 !important"
                    dense
                    hide-details
                    @change="checkroomscansaveFunc(element)"
                  ></v-checkbox>
                </template>
              </div>
            </div>
            <v-radio-group v-else v-model="element.check" row color="#1e9ff2">
              <v-radio
                color="#1e9ff2"
                :label="$t('SigninHealthandSafety.btn_yes')"
                :value="true"
              ></v-radio>
              <v-radio
                color="#1e9ff2"
                :label="$t('SigninHealthandSafety.btn_no')"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="#666ee8"
          block
          dark
          depressed
          class="mb-5"
          style="text-transform: capitalize !important"
          :disabled="!cansavehealth"
          @click="dialogConfirmSave = true"
        >
          <span>{{ $t("SigninHealthandSafety.btn_save") }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogConfirmSave" persistent max-width="360">
      <v-card>
        <v-card-title
          class="text-center"
          style="font-size: 1rem !important; letter-spacing: normal !important"
          >{{ $t("Alert.doyouwanttosave") }}</v-card-title
        >
        <v-card-actions class="justify-center align-center pb-5">
          <v-btn
            depressed
            dark
            color="#e83e8c"
            class="pa-3 mr-3"
            @click="saveHealthAndSafety()"
            >{{ $t("SigninGeneral.btn_save") }}</v-btn
          >
          <v-btn color="pink" class="pa-3" outlined @click="dialogConfirmSave = false">{{
            $t("SigninGeneral.btn_cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import _ from "lodash";

export default {
  name: "SigninHealthAndSafetyScreen",
  metaInfo: {
    title: "Health & safety",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else next();
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    propertyData: null,
    loading: false,
    loadingSave: false,
    cansavehealth: false,
    itemsOldHealth: [],
    itemsHealth: [],
    itemsDefaultHealth: [],
    itemsHealthAndSafety: [],
    itemsRoomName: [],
    dialogConfirmSave: false,
    itemsOldHealthAndSafety: [],
    saveSuccess: false,
    loadingData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  mounted() {
    const self = this;
    EventBus.$on("refreshRightPhoto", self.initAll);
  },
  watch: {
    itemsHealth() {
      this.setHealthAndSafety();
    },
    itemsDefaultHealth() {
      this.setHealthAndSafety();
    },
  },
  methods: {
    initAll() {
      const self = this;
      self.initPropertyData();
      self.initDefaultHealth();
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var tempHealth = [];
      var tempRoomName = [];
      self.loadingData = true;
      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
          tempHealth = temp?.health_and_safety || [];
          if (temp?.rooms?.length) {
            temp?.rooms?.map((el) => {
              tempRoomName.push({
                _id: el._id,
                room_name: el.room_name,
                nameid: el.room_name._id,
                namevalue: el.room_name.value,
                room_type: el.room_type,
                check: false,
              });
            });
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        self.itemsOldHealth = tempHealth;
        self.itemsHealth = tempHealth;
        self.itemsRoomName = tempRoomName;
        self.setOldHealthAndSafety();
        self.loadingData = false;
      }
    },
    async initDefaultHealth() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/setting/health", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          temp = res.data.result;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsDefaultHealth = temp;
      }
    },
    setHealthAndSafety() {
      const self = this;
      EventBus.$emit("loadingtillend");

      var temp = [];
      var tempCategory = [];
      var tempRoom = [];

      if (self?.itemsHealth?.length) {
        self?.itemsHealth?.map((el) => {
          if (el?.health_and_safety?.value) {
            tempRoom = [];
            self.itemsRoomName.map((ele) => {
              const findRoom = el?.rooms?.find((p) => p._id == ele._id);
              if (findRoom?._id) {
                tempRoom.push({
                  ...ele,
                  check: true,
                });
              } else tempRoom.push(ele);
            });
            temp.push({
              choice_type: el?.health_and_safety?.choice_type,
              check: el?.value || false,
              checkrooms:
                el?.value && el?.rooms?.length == self?.itemsRoomName?.length
                  ? "all"
                  : el?.value && el?.rooms?.length < self?.itemsRoomName?.length
                  ? "some"
                  : "none",
              rooms: tempRoom || self.itemsRoomName,
              _id: el._id,
              category: el?.health_and_safety?.category,
              categoryid: el?.health_and_safety?.category?._id,
              categoryname: el?.health_and_safety?.category?.value,
              value: el?.health_and_safety?.value,
              valueid: el?.health_and_safety?._id,
            });
          }
        });
        const group = _.groupBy(temp, "category._id");
        var no = 0;
        Object.keys(group).map((key) => {
          if (key !== "undefined") {
            tempCategory.push({
              no: no + 1,
              category_id: key,
              category_name: group[key][0].category.value,
              health_and_safety: group[key].map((el) => el),
            });
          }
        });
        if (tempCategory?.length) self.cansavehealth = true;
        else self.cansavehealth = false;
        self.itemsHealthAndSafety = tempCategory;
      } else {
        self?.itemsDefaultHealth?.map((el) => {
          temp.push({
            choice_type: el?.choice_type,
            check: false,
            checkrooms: "none",
            rooms: self.itemsRoomName,
            _id: el._id,
            category: el?.category,
            categoryid: el?.category?._id,
            categoryname: el?.category?.value,
            value: el?.value,
            valueid: el?._id,
          });
        });
        const group = _.groupBy(temp, "category._id");
        var no = 0;
        Object.keys(group).map((key) => {
          if (key !== "undefined") {
            tempCategory.push({
              no: no + 1,
              category_id: key,
              category_name: group[key][0].category.value,
              health_and_safety: group[key].map((el) => el),
            });
          }
        });
        if (tempCategory?.length) self.cansavehealth = true;
        else self.cansavehealth = false;
        self.itemsHealthAndSafety = tempCategory;
      }

      EventBus.$emit("endloading");
    },
    setOldHealthAndSafety() {
      const self = this;
      EventBus.$emit("loadingtillend");

      var temp = [];
      var tempOldCategory = [];
      var tempRoom = [];

      if (self?.itemsOldHealth?.length) {
        self?.itemsOldHealth?.map((el) => {
          if (el?.health_and_safety?.value) {
            tempRoom = [];
            self.itemsRoomName.map((ele) => {
              const findRoom = el?.rooms?.find((p) => p._id == ele._id);
              if (findRoom?._id) {
                tempRoom.push({
                  ...ele,
                  check: true,
                });
              } else tempRoom.push(ele);
            });
            temp.push({
              choice_type: el?.health_and_safety?.choice_type,
              check: el?.value || false,
              checkrooms:
                el?.value && el?.rooms?.length == self?.itemsRoomName?.length
                  ? "all"
                  : el?.value && el?.rooms?.length < self?.itemsRoomName?.length
                  ? "some"
                  : "none",
              rooms: tempRoom || self.itemsRoomName,
              _id: el._id,
              category: el?.health_and_safety?.category,
              categoryid: el?.health_and_safety?.category?._id,
              categoryname: el?.health_and_safety?.category?.value,
              value: el?.health_and_safety?.value,
              valueid: el?.health_and_safety?._id,
            });
          }
        });
        const group = _.groupBy(temp, "category._id");
        var no = 0;
        Object.keys(group).map((key) => {
          if (key !== "undefined") {
            tempOldCategory.push({
              no: no + 1,
              category_id: key,
              category_name: group[key][0].category.value,
              health_and_safety: group[key].map((el) => el),
            });
          }
        });
      } else {
        self?.itemsDefaultHealth?.map((el) => {
          temp.push({
            choice_type: el?.choice_type,
            check: false,
            checkrooms: "none",
            rooms: self.itemsRoomName,
            _id: el._id,
            category: el?.category,
            categoryid: el?.category?._id,
            categoryname: el?.category?.value,
            value: el?.value,
            valueid: el?._id,
          });
        });
        const group = _.groupBy(temp, "category._id");
        var no = 0;
        Object.keys(group).map((key) => {
          if (key !== "undefined") {
            tempOldCategory.push({
              no: no + 1,
              category_id: key,
              category_name: group[key][0].category.value,
              health_and_safety: group[key].map((el) => el),
            });
          }
        });
      }

      self.itemsOldHealthAndSafety = tempOldCategory;

      EventBus.$emit("endloading");
    },
    gobackFunc() {
      this.loading = true;
      EventBus.$emit("goBackPage");
    },
    checkroomscansaveFunc(item) {
      const self = this;
      self.cansavehealth = false;
      if (item.checkrooms == "some") {
        item.rooms.map((el) => {
          if (el.check) self.cansavehealth = true;
        });
      } else self.cansavehealth = true;
    },
    async saveHealthAndSafety() {
      const self = this;
      self.dialogConfirmSave = false;
      var obj = null;
      var temp = [];
      var tempRooms = [];
      self.loading = true;
      self.cansavehealth = false;
      try {
        self?.itemsHealthAndSafety?.map((el) => {
          el?.health_and_safety?.map((ele) => {
            tempRooms = [];
            if (ele?.rooms?.length) {
              ele?.rooms?.map((element) => {
                if (ele.checkrooms == "all") {
                  tempRooms.push(element._id);
                } else if (ele.checkrooms == "some") {
                  if (element.check) tempRooms.push(element._id);
                } else tempRooms = [];
              });
            }
            if (ele.choice_type == "boolean") {
              temp.push({
                _id: ele._id,
                value: ele.check,
                // rooms: []
              });
            } else {
              temp.push({
                _id: ele._id,
                value: tempRooms?.length ? true : false,
                rooms: tempRooms,
              });
            }
          });
        });
        self.confirmSaveMultiple(temp);
      } catch (error) {
      } finally {
        self.loading = false;
        self.cansavehealth = true;
      }
    },
    async confirmSave(obj, healthid) {
      const self = this;
      EventBus.$emit("loadingtillend");
      self.saveSuccess = false;

      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API + `/property/health?health_id=${healthid}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.saveSuccess = true;
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        self.loading = false;
        self.cansaveroomamenity = true;
        EventBus.$emit("endloading");
        self.initAll();
      }
    },
    async confirmSaveMultiple(obj) {
      const self = this;
      EventBus.$emit("loadingtillend");
      self.saveSuccess = false;
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API + `/property/health?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.saveSuccess = true;
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        self.loading = false;
        self.cansaveroomamenity = true;
        EventBus.$emit("endloading");
        self.initAll();
      }
    },
    checkChangeData() {
      const self = this;
      var cantleavethispage = false;
      var emptyValueCategory = false;
      self.itemsHealthAndSafety?.map((el) => {
        const findCategory = self?.itemsOldHealthAndSafety?.find(
          (p) => p.category_id == el?.category_id
        );
        // console.log("findCategory: ", findCategory, this.propertyData);
        el?.health_and_safety?.map((ele, i) => {
          const findCategoryName = findCategory?.health_and_safety?.find(
            (p) => p._id == ele?._id
          );
          // console.log("findCategoryName: ", findCategoryName);
          if (findCategoryName?._id && ele?.check !== findCategoryName?.check) {
            emptyValueCategory = true;
          }
        });
      });
      if (self.saveSuccess) {
        cantleavethispage = false;
      } else if (emptyValueCategory) {
        cantleavethispage = true;
      } else cantleavethispage = false;
      return cantleavethispage;
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";

.v-skeleton-loader__button {
  width: 100% !important;
}
</style>
